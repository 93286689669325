<template>
  <div class="container">
     <content-title :nav="nav"></content-title>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >

    <template #handle={row}>
      <el-button size="small" type="primary" @click="userDetail(row)">用户详情</el-button>
    </template>

    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
         nav: { firstNav: "用户比价信息", secondNav: "用户比价信息列表" },
      DataList: [],
      Option: [
        { name: "标题", value: "title" },
        { name: "类目", value: "categories_child" },
        { name: "课节", value: "course_num" },
         { name: "适用人群", value: "school_age" },
        { name: "价格", value: "discount_amout" },
         { name: "班级人数", value: "connect_peoplenum" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    userDetail(row){
        this.$router.push({ name: 'userDetails', params: { id: row.user_id } });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/masterSetPrice/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          type: "compare_course",
          status: 4,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>